<template>
  <span @click="presentAlert"><fa icon="video" class="fas-icon"></fa></span>
</template>

<script>
import { alertController } from '@ionic/vue';
import axios from 'axios';
import useDataService from "../dataservice";

export default {
	name: 'VideoLink',
	methods: {
		async presentAlert() {
			const alert = await alertController
			.create({
				cssClass: 'my-custom-class',
				header: 'Добавить видеоролик',
				message: 'Вставь ссылку на ролик YouTube или TikTok',
				inputs: [{
					name: 'form',
					type: 'textarea',
					placeholder: 'http://...',
				}],
				buttons: [{
					text: 'Ok',
					handler: (alertData) => {
						const vurl = alertData.form;	
						if (/(youtube\.com\/watch\?v=|youtu.be\/)/.test(vurl)) {
							const videoRegex = /(https?:\/\/)?(www\.|m\.)?(youtube\.com\/watch\?v=|youtu.be\/)([^\s&?$]*)(&.*)?(\?.*)?/;
							this.$emit('video-add', vurl.replace(videoRegex, "[video-youtube-$4]"));
						} else if (/tiktok\.com/.test(vurl)){
							if(/vm\.tiktok/.test(vurl)){
								const { token } = useDataService();
								// eslint-disable-next-line
								const ths = this;
								axios.post('https://beon.fun/api/v1/misc/scurl/', {
									url: vurl
								},{
									headers: {
										'Authorization': 'Bearer ' + token.value,
									}
								})
								.then(function (response) {
									const videoRegex = /^https:\/\/m\.tiktok\.com\/v\/([\d]+)\.html\??.*$/;
									ths.$emit('video-add', response.data.url.replace(videoRegex, "[video-tiktok-$1]"));
								})
							} else {
								const videoRegex = /(https?:\/\/)?(www\.)?tiktok.com\/.+?\/video\/([\d]+)?\??.*?$/;
								this.$emit('video-add', vurl.replace(videoRegex, "[video-tiktok-$3]"));
							}
						}
					},
				},
				'Cancel'
				]
			});
			return alert.present();
		}
	}
}

</script>