<template>
  <span @click="presentAlert"><fa icon="music" class="fas-icon"></fa></span>
</template>

<script>
import { alertController, toastController } from '@ionic/vue';
import axios from 'axios';
import useDataService from "../dataservice";
//import { defineComponent } from 'vue';
export default {
	name: 'AudioLink',
	methods: {
		async presentAlert() {
			const alert = await alertController
			.create({
			cssClass: 'my-custom-class',
				header: 'Добавить аудиофайл',
				message: 'Вставь ссылку на трек (Soundcloud, Yandex.Music, Spotify, Voocaroo)',
				inputs: [{
					name: 'audioform',
					type: 'textarea',
					placeholder: 'http://...',
				}],
				buttons: [{
					text: 'Ok',
					handler: (alertData) => {
						const audioUrl = alertData.audioform;
						if (/api\.soundcloud\.com\/tracks\//.test(audioUrl)) {
							const audioRegex = /<iframe .*? src="https:\/\/w.soundcloud.com\/player\/\?url=https%3A\/\/api.soundcloud.com\/tracks\/([0-9]*).*?"><\/iframe>/;
							this.$emit('audio-add', audioUrl.replace(audioRegex, "[audio-soundcloud-track-$1]"));
						} else if (/api\.soundcloud\.com\/playlists\//.test(audioUrl)) {
							const audioRegex = /<iframe .*? src="https:\/\/w.soundcloud.com\/player\/\?url=https%3A\/\/api.soundcloud.com\/playlists\/([0-9]*).*?"><\/iframe>/;
							this.$emit('audio-add', audioUrl.replace(audioRegex, "[audio-soundcloud-playlist-$1]"));
						} else if (/soundcloud\.com\/.+\/.+/.test(audioUrl)){
							axios
							.get('https://api.soundcloud.com/resolve?url='+ audioUrl +'&client_id=21832d295e3463208d2ed0371ae08791')
							.then((response) => {
								const rurl = response.request.responseURL;
								if (/api\.soundcloud\.com\/tracks\//.test(rurl)) {
									const audioRegex = /https:\/\/api.soundcloud.com\/tracks\/([0-9]*)?(\?client_id=21832d295e3463208d2ed0371ae08791)?.*?/;
									this.$emit('audio-add', rurl.replace(audioRegex, "[audio-soundcloud-track-$1]"));
								} else if (/api\.soundcloud\.com\/playlists\//.test(rurl)) {
									const audioRegex = /https:\/\/api.soundcloud.com\/playlists\/([0-9]*)?(\?client_id=21832d295e3463208d2ed0371ae08791)?.*?/;
									this.$emit('audio-add', rurl.replace(audioRegex, "[audio-soundcloud-playlist-$1]"));
								} else {
									this.openToast();
									return false;
								}
							}).catch(()  => {
								this.openToast();
								return false;
							});
						} else if (/music\.yandex\.ru\/album\/[0-9]*\/track\/[0-9]*/.test(audioUrl)) {
							const audioRegex = /(https?:\/\/)?music.yandex.ru\/album\/([0-9]*)\/track\/([0-9]*)/;
							this.$emit('audio-add', audioUrl.replace(audioRegex, "[audio-yamusic-track-$3-$2]"));
						} else if (/music\.yandex\.ru\/album\/[0-9]*$/.test(audioUrl)) {
							const audioRegex = /(https?:\/\/)?music.yandex.ru\/album\/([0-9]*)$/;
							this.$emit('audio-add', audioUrl.replace(audioRegex, "[audio-yamusic-album-$2]"));
						} else if(/spotify\.com\/(album|track|playlist)\/(.*)/.test(audioUrl)){
							const audioRegex = /(https?:\/\/)?open\.spotify\.com\/(album|track|playlist)\/(.*)/;
							this.$emit('audio-add', audioUrl.replace(audioRegex, "[audio-spotify-$2-$3]"));
						} else if(/https?:\/\/soundcloud\.app\.goo\.gl\/(.*)/.test(audioUrl)){
							const { token } = useDataService();
							axios
							.post('https://beon.fun/api/v1/misc/scurl',{ url: audioUrl}, { headers: {
								Authorization: 'Bearer ' + token.value,
							}}).then((resp) => {
								axios
								.get('https://api.soundcloud.com/resolve?url='+ resp.data.url +'&client_id=21832d295e3463208d2ed0371ae08791')
								.then((response) => {
									const rurl = response.request.responseURL;
									if (/api\.soundcloud\.com\/tracks\//.test(rurl)) {
										const audioRegex = /https:\/\/api.soundcloud.com\/tracks\/([0-9]*)?(\?client_id=21832d295e3463208d2ed0371ae08791)?.*?/;
										this.$emit('audio-add', rurl.replace(audioRegex, "[audio-soundcloud-track-$1]"));
									} else if (/api\.soundcloud\.com\/playlists\//.test(rurl)) {
										const audioRegex = /https:\/\/api.soundcloud.com\/playlists\/([0-9]*)?(\?client_id=21832d295e3463208d2ed0371ae08791)?.*?/;
										this.$emit('audio-add', rurl.replace(audioRegex, "[audio-soundcloud-playlist-$1]"));
									} else {
										this.openToast();
										return false;
									}
								}).catch(() => {
									this.openToast();
									return false;
								})
							});
						} else if(/vocaroo\.com\/(.*)/.test(audioUrl) || /voca\.ro\/(.*)/.test(audioUrl)){
							const audioRegex = /(https?:\/\/)?(vocaroo\.com|voca\.ro)\/(.*)/;
							this.$emit('audio-add', audioUrl.replace(audioRegex, "[audio-vocaroo-$3]"));
						} else {
							this.openToast();
							return false;
						}
					},
				},
				'Cancel'
				]
			});
			return alert.present();
		},
		async openToast() {
			const toast = await toastController
				.create({
				message: 'Проверьте правильность ссылки',
				duration: 2000
			});
			return toast.present();
		},
	}
}

</script>