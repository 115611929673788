<template>
  <span @click="presentAlert"><fa icon="user" class="fas-icon"></fa></span>
</template>

<script>
import { IonButton, alertController } from '@ionic/vue';
//import { defineComponent } from 'vue';
export default {
	name: 'UserLink',
	methods: {
		async presentAlert() {
			const alert = await alertController
			.create({
			cssClass: 'my-custom-class',
				header: 'Ссылка на пользователя',
				message: 'Вставь логин (не никнейм!) пользователя',
				inputs: [{
					name: 'usrform',
					placeholder: 'Логин пользователя',
				}],
				buttons: [{
					text: 'Ok',
					handler: (alertData) => {
						this.$emit('user-add', alertData.usrform);
					},
				},
				'Cancel'
				]
			});
			return alert.present();
		}
	}
}

</script>