<template>
  <span @click="presentAlert"><fa icon="file-code" class="fas-icon"></fa></span>
</template>

<script>
import { alertController } from '@ionic/vue';
import axios from 'axios';
import useDataService from "../dataservice";

export default {
	name: 'InstaLink',
	methods: {
		async presentAlert() {
			const alert = await alertController
			.create({
				cssClass: 'my-custom-class',
				header: 'Добавить пост из соцсети',
				message: 'Вставь ссылку на запись в Instagram или Pinterest',
				inputs: [{
					name: 'form',
					type: 'textarea',
					placeholder: 'http://...',
				}],
				buttons: [{
					text: 'Ok',
					handler: (alertData) => {
						const vurl = alertData.form;
						if (/instagram\.com/.test(vurl)){
							const videoRegex = /^.*(https?:\/\/)?(www\.)?instagram\.com\/(p|reel)\/([^\s&?$/]*).*$/;
							this.$emit('insta-add', vurl.replace(videoRegex, "[insta-$4]"));
						} else if (/pinterest\.(com|ru)/.test(vurl)){
							const videoRegex = /^.*(https?:\/\/)?(www\.)?pinterest\.(com|ru)\/pin\/([\d]*).*$/;
							this.$emit('insta-add', vurl.replace(videoRegex, "[pin-$4]"));
						} else if (/pin.it\//.test(vurl)){
							const { token } = useDataService();
							// eslint-disable-next-line
							const ths = this;
							axios.post('https://beon.fun/api/v1/misc/scurl/', {
								url: vurl
							},{
								headers: {
									'Authorization': 'Bearer ' + token.value,
								}
							})
							.then(function (response) {
								const videoRegex = /^.*(https?:\/\/)?(www\.)?pinterest\.(com|ru)\/pin\/([\d]*).*$/;
								ths.$emit('insta-add', response.data.url.replace(videoRegex, "[pin-$4]"));
							})
						}
					},
				},
				'Cancel'
				]
			});
			return alert.present();
		}
	}
}
</script>